import React from "react";
import { Helmet } from "react-helmet";
import { websiteUrl } from "../constants/BaseConstants";

function MetaTags() {
  const iconUrl = `${websiteUrl}/images/quran-pdfs-icon.png`;
  const infographicUrl = `${websiteUrl}/images/quran-pdfs-infographic.png`;

  const title = "Quran PDFs for Download. All High Quality Files, For mobile and computer.";
  let description = `Download high quality Quran PDF files. Arabic, English, and Arabic with English.`;

  return (
    <Helmet
      // structured data
      script={[
        {
          type: "application/ld+json",
          innerHTML: `{
            "@context": "https://schema.org",
            "@type": "Website",
            "url": "${websiteUrl}",
            "name": "Quran PDFs",
            "description": "${description}",      
            "author": "Talal Itani",
            "logo": "${iconUrl}",
            "image": "${iconUrl}"
          }`
        }
      ]}
    >
      <html lang="en" />

      <link rel="canonical" href={websiteUrl} />

      {/* meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content="Talal Itani" />
      <meta name="robots" content="index, follow" />

      <meta property="og:url" content={websiteUrl} />
      <meta property="og:type" content="book" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={infographicUrl} />
      <meta property="og:image:secure_url" content={iconUrl} />
      <meta property="og:site_name" content="Quran in English" />
      <meta property="fb:admins" content="683971979" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@QuranPDFs" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="@QuranPDFs" />
      <meta name="twitter:image" content={infographicUrl} />
    </Helmet>
  );
}

export default MetaTags;
