import { EBook } from "../models/EBook";

export const eBooks: EBook[] = [
  new EBook(
    "quran-in-english-and-arabic",
    "PDF eBook",
    "Quran in English and Arabic PDF eBook. The text is clear and easy to read. With simple English translation.",
    "Quran in English and Arabic PDF eBook"
  ),
  new EBook("quran", "PDF eBook", "Quran Arabic PDF eBook. The text is clear and easy to read. Full Quran.", "Quran Arabic PDF eBook"),
  new EBook(
    "quran-in-english",
    "PDF eBook",
    "Quran in English PDF eBook. The text is clear and easy to read. The translation is easy to understand. Uses the name God to refer to the Creator.",
    "Quran in English PDF eBook"
  ),
  new EBook(
    "quran-english-translation",
    "PDF eBook",
    "Quran English Translation PDF eBook. The text is clear and easy to read. The translation is easy to understand. Uses the name Allah to refer to the Creator.",
    "Quran English Translation PDF eBook"
  )
];
