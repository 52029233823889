export class EBook {
  public name: string;
  public title: string;
  public description: string;
  public alt: string;

  public constructor(name: string, title: string, description: string, alt: string) {
    this.name = name;
    this.title = title;
    this.description = description;
    this.alt = alt;
  }
}
