import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MetaTags from "../components/MetaTags";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import AppBarPDFs from "../components/AppBarPDFs";
import ImageCard from "../components/ImageCard";
import { EBook } from "../models/EBook";
import { eBooks } from "../constants/EBooks";

const topOffset = "120px";

const useStyles = makeStyles({
  cardsContainer: {
    paddingTop: topOffset,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  spacer: {
    height: "20px"
  }
});

const mine = createMuiTheme({
  palette: {
    background: {
      default: grey[200]
    }
  }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PDFs(props: any) {
  const classes = useStyles(props);

  return (
    <MuiThemeProvider theme={mine}>
      <CssBaseline />
      <div className={classes.cardsContainer}>
        <AppBarPDFs title="Quran PDFs" />
        {eBooks.map((eBook: EBook) => (
          <ImageCard
            key={eBook.name}
            name={eBook.name}
            title={eBook.title}
            description={eBook.description}
            alt={eBook.alt}
            cover={`../../images/${eBook.name}/cover.png`}
          />
        ))}
        <div className={classes.spacer} />
      </div>
      <MetaTags />
    </MuiThemeProvider>
  );
}

export default PDFs;
