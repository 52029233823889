import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const appBarColor = "#000000";

const useStyles = makeStyles({
  toolBarContent: {
    display: "flex",
    justifyContent: "center"
  },
  appbarTitle: {
    fontSize: "1.9rem",
    ["@media (min-width:350px)"]: {
      fontSize: "2.2rem"
    },
    letterSpacing: "0.15rem"
  },
  appBar: {
    backgroundColor: appBarColor
  }
});

interface AppBarPDFsProps {
  title: string;
}

function AppBarPDFs(props: AppBarPDFsProps) {
  const classes = useStyles(props);

  return (
    <div id="app-bar">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBarContent}>
          <Typography className={classes.appbarTitle} variant="h1">
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppBarPDFs;
