import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { downloadsUrl } from "../constants/BaseConstants";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const useStyles = makeStyles({
  root: {
    maxWidth: "312px",
    margin: "0px 12px 55px 12px"
  }
});

interface ImageCardProps {
  name: string;
  title: string;
  description: string;
  alt: string;
  cover: string;
}

function ImageCard(props: ImageCardProps) {
  const classes = useStyles(props);

  function handleDownload(name: string, type: string) {
    window.open(`${downloadsUrl}${name}.${type}`);
    trackCustomEvent({
      category: `${name}.${type}`,
      action: "eBook Download",
      label: "eBook Download",
      value: 0
    });
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => handleDownload(props.name, "pdf")}>
        <CardMedia component="img" alt={props.alt} image={props.cover} title={props.alt} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="medium" color="primary" onClick={() => handleDownload(props.name, "pdf")}>
          Download
        </Button>
      </CardActions>
    </Card>
  );
}

export default ImageCard;
